import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { Typography } from 'antd';
import React from 'react';

import { ContentfulRichText } from '../../types/contentful';

export const richTextToReactComponents = (
  richTextObject: ContentfulRichText,
): React.ReactNode => {
  if (!richTextObject) return;

  const aggregatedReferences = richTextObject?.references?.reduce(
    (acc, item) => ({
      ...acc,
      [item.contentful_id]: { ...item },
    }),
    {},
  );

  const richTextGeneratorOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_, children) => (
        <Typography.Paragraph>{children}</Typography.Paragraph>
      ),

      [BLOCKS.EMBEDDED_ASSET]: node => {
        const imageData = aggregatedReferences[node?.data?.target?.sys?.id];

        return <img src={imageData?.file?.url} alt={imageData?.title} />;
      },

      [BLOCKS.HEADING_1]: (_, children) => (
        <Typography.Title level={1}>{children}</Typography.Title>
      ),

      [BLOCKS.HEADING_2]: (_, children) => (
        <Typography.Title level={2}>{children}</Typography.Title>
      ),

      [BLOCKS.HEADING_3]: (_, children) => (
        <Typography.Title level={3}>{children}</Typography.Title>
      ),

      [BLOCKS.HEADING_4]: (_, children) => (
        <Typography.Title level={4}>{children}</Typography.Title>
      ),

      [BLOCKS.HEADING_5]: (_, children) => (
        <Typography.Title level={5}>{children}</Typography.Title>
      ),
    },
  };

  return documentToReactComponents(
    typeof richTextObject.raw === 'string'
      ? JSON.parse(richTextObject.raw)
      : richTextObject.raw,
    richTextGeneratorOptions,
  );
};
